var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c(
                "a-tree",
                {
                  staticClass: "template-5-tree",
                  attrs: {
                    "tree-data": _vm.treeData,
                    "show-icon": "",
                    "show-line": "",
                    expandedKeys: _vm.treeExpandedKeys,
                    selectedKeys: [_vm.pagination.current],
                  },
                  on: {
                    expand: _vm.handleTreeExpand,
                    select: _vm.handleTreeSelect,
                  },
                },
                [
                  _c("a-icon", {
                    staticStyle: { color: "#0c8fcf" },
                    attrs: { slot: "myIcon", type: "unordered-list" },
                    slot: "myIcon",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c("j-vxe-table", {
                attrs: {
                  "row-number": "",
                  "row-selection": "",
                  height: 750,
                  loading: _vm.loading,
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  pagination: _vm.pagination,
                },
                on: { pageChange: _vm.handleTablePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }